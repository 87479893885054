<script setup>
import { useForm } from '@inertiajs/vue3';
import { inject, ref } from 'vue';

// Components
import BlogArticle from '@/Components/Blog/BlogArticle.vue';
import FilterButtons from '@/Components/FilterButtons.vue';
import Pagination from '@/Components/Pagination/PaginationWebsite.vue';

const route = inject('route');

const props = defineProps({
    query: Object,
    items: Object,
    blogArticles: Object,
    categoryOptions: Object,
});

const allBlogs = ref([...props.blogArticles.data]);

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;
    form.get(route('blogs.index'), {});
};

const form = useForm({
    page: props.blogArticles.page ?? 1,
    currentCategories: props.query.currentCategories ?? [],
});

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <div class="container pb-70">
        <FilterButtons
            :categoryOptions="categoryOptions"
            v-model:currentCategories="form.currentCategories"
            @update:current-categories="getData()"
        />

        <div class="grid gap-8 mb-6 md:grid-cols-2 xl:grid-cols-3">
            <template v-for="blog in allBlogs" :key="blog.id">
                <BlogArticle :blog="blog" />
            </template>
        </div>

        <Pagination
            v-if="blogArticles !== 'undefined'"
            :links="blogArticles.links"
            @setPage="setPage"
            :pageData="blogArticles"
        />
    </div>
</template>
